.auth-wrapper {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: calc(100vh - 62px);
  margin-top: -69px;
  background: url(../../../images/awg/auth/auth-background.png) bottom / cover;

  .auth-btn {
    background: linear-gradient(92.6deg, #FFCB7D -4.11%, #EBAF4D 57.66%), #F5BE63;
    border-radius: 5.0076px;
    
    span {
      color: #000002 !important;
      font-weight: 500;
    }
  
    &:hover {
      background: linear-gradient(92.6deg, #FFCB7D 100%, #EBAF4D 100%), #F5BE63;
      span {
        color: #000002 !important;
      }
    }
  }

  .adworld-logo {
    padding-bottom: 20px;
    text-align: center;
  }

  &-form-container {
    z-index: 1;
    width: 550px;
    max-width: 95%;
    padding: 70px;
    margin: 25px 15px 50px 15px;
    background: linear-gradient(166.5deg, #DA295D -92.23%, rgba(218, 41, 93, 0) 96.49%), #74233B;
    border: none;
    box-shadow: 0px 20px 40px #FADED1;
    border-radius: 12px;
    position: relative;

    .auth-popup-bg {
      position: absolute;
      z-index: -1;
      height: calc(100% + 6px);
      width: calc(100% + 6px);
      top: -3px;
      bottom: 0;
      right: 0;
      left: -3px;
    }

    .logo {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 40px;
      z-index: 2;

      img {
        height: 60px;
      }

      .title {
        font-size: 23px;
        color: rgb(24, 26, 85);
        text-transform: uppercase;
        letter-spacing: 0.1em;

        strong {
          font-weight: 700;
        }
      }
    }

    .ant-form {
      &-item {
        padding: 0 25px;
        color: #fff;

        span {
          color: #fff;
        }

        a {
          color: #fff;
          text-decoration: underline;
        }

        &:last-of-type {
          margin-bottom: 0;
        }

        &.password-form-item {
          margin-bottom: 7px;
        }

        &.ant-form-item-has-error {
          .ant-form-item-control-input {
            .ant-input-affix-wrapper {
              border-color: rgb(253, 69, 78);
            }
          }
        }

        .ant-form-item-label label {
          color: #fff;
        }

        &-control-input {
          .ant-input-affix-wrapper {
            padding: 8px 12px;
            border-color: rgb(233, 233, 233);
            border-width: 2px !important;
            border-radius: 5px;
            box-shadow: none;

            .ant-input-prefix {
              margin-right: 10px;

              svg {
                color: rgb(172, 172, 172);
              }
            }

            input {
              font-size: 15px;
            }
          }

          &-content {
            > input {
              padding: 8px 12px;
              font-size: 15px;
              border-color: rgb(233, 233, 233);
              border-width: 2px !important;
              border-radius: 5px;
              box-shadow: none;
            }
          }
        }

        .ant-form-item-explain {
          margin-bottom: 10px;
          color: #fff;
        }
      }

      hr {
        height: 1px;
        margin: 15px 0;
        background-color: rgb(224, 224, 224) !important;
        border: none;
      }

      .forgot-password {
        padding: 0 25px;
        margin-bottom: 14px;
        font-weight: 700;
        text-align: right;

        a {
          font-size: 15px;
          color: #fff;
          background-clip: text;

          &:hover {
            text-decoration: underline;
          }
        }
      }

      .didnt-receive-login {
        font-size: 14px;
        font-weight: 700;
        color: #fff;
        background-clip: text;

        &:hover {
          text-decoration: underline;
        }
      }
    }
  }
}
